import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import CarrierContentsForm from "./CarrierContentsForm";

const CarrierContentsTable = ({ token }) => {
  let [carrierTypes, setCarrierTypes] = useState([]);
  const [selectedCarrierType, setSelectedCarrierType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCarrierTypeId, setSelectedCarrierTypeId] = useState(null);

  useEffect(() => {
    getCarrierTypes();
  }, []);

  let getCarrierTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/CarrierType/', requestOptions);
      let data = await response.json();
      setCarrierTypes(data);
    } catch (error) {
      console.error('Error fetching carrier types:', error);
    }
  };

  const handleRowSelect = (selectedRow) => {
    setSelectedProduct(selectedRow['Product Name']);
    setSelectedCarrierType(selectedRow);
    setSelectedCarrierTypeId(selectedRow.Unique_id);
  };

  const initialColumns = ['Carrier Type Name', 'Product Name', 'Max Amount', 'Min Amount', 'Red Tag Amount'];
  const allColumns = ['Unique_id', 'Carrier Type Name', 'Product Name', 'Max Amount', 'Min Amount', 'Red Tag Amount'];

  const tableData = carrierTypes.map(type => ({
    'Carrier Type Name': type.carrier_type_name || '',
    'Product Name': type.product_name || '',
    'Max Amount': type.max_amount || '',
    'Min Amount': type.min_amount || '',
    'Red Tag Amount': type.red_tag_amount || '',
    'Unique_id': type.unique_id,
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <div className="form-container">
          <CarrierContentsForm selectedCarrierType={selectedCarrierType} setCarrierTypes={setCarrierTypes} token={token} />
        </div>
      </div>
      <div className="carrier-container">
        <div className="Carrier-list" style={{ flex: 1 }}>
          <EditableTable 
            selectedProduct={selectedProduct} 
            selectedCarrierTypeId={selectedCarrierTypeId} 
            setSelectedCarrierTypeId={setSelectedCarrierTypeId} 
            token={token} 
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns}
            allColumns={allColumns}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
};

export default CarrierContentsTable;