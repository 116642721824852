import React, { useState, useEffect } from 'react';
import EditableTable from "../Table/Table";
import ConsumableBatchForm from "./ConsumableBatchForm";

const ConsumableBatchTable = ({ token }) => {
  let [consumable, setConsumable] = useState([]);
  const [selectedConsumable, setSelectedConsumable] = useState(null);
  const [selectedConsumableId, setSelectedConsumableId] = useState(null);

  useEffect(() => {
    getConsumable();
  }, []);

  let getConsumable = async () => {
    try {
      let response = await fetch('/api/Consumable/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      setConsumable(data);
    } catch (error) {
      console.error('Error fetching consumable:', error);
    }
  };

  const initialColumns = ['Product_Name', 'Batch_Number', 'Expiry_Date', 'Consumable_Pricing', 'Supplier', 'Pack_size', 'Type', 'Status'];
  const allColumns = ['Product_Name', 'Batch_Number', 'Expiry_Date', 'Consumable_Pricing', 'Product_Barcode', 'Added_by', 'Date_added', 'Supplier', 'Pack_size', 'Type', 'Status', 'Unique_id'];

  const tableData = Array.isArray(consumable) ? consumable.map(consumable => ({
    'Product_Name': consumable.Product_Name,
    'Batch_Number': consumable.Batch_Number,
    'Expiry_Date': new Date(consumable.Expiry_Date).toLocaleDateString(),
    'Consumable_Pricing': consumable.Consumable_Pricing,
    'Product_Barcode': consumable.Product_Barcode,
    'Added_by': consumable.Added_by,
    'Date_added': new Date(consumable.Date_added).toLocaleDateString(),
    'Supplier': consumable.Supplier,
    'Pack_size': consumable.Pack_size,
    'Type': consumable.Type,
    'Status': consumable.Status,
    'Unique_id': consumable.Unique_id,
  })) : [];

  const handleRowSelect = (selectedRow) => {
    setSelectedConsumable(selectedRow);
    setSelectedConsumableId(selectedRow.Unique_id);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="form-container">
        <ConsumableBatchForm selectedconsumable={selectedConsumable} token={token} />
      </div>
      <div className="consumable-container">
        <div className="consumable-list">
          <EditableTable
            selectedconsumable={selectedConsumable}
            selectedconsumableId={selectedConsumableId}
            setSelectedconsumableId={setSelectedConsumableId}
            token={token}
            onRowSelect={handleRowSelect}
            initialColumns={initialColumns}
            allColumns={allColumns}
            data={tableData}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsumableBatchTable;