import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import EditMedicine from './EditMedicine';
import EditUsers from './EditUsers';
import EditCarrier from './EditCarrier';
import EditStation from './EditStation';
import EditUserPosition from './EditUserPosition'; 
import EditConsumbaleBatch from './EditConsumableBatch';
import StorageEditPage from './StorageEditPage';
import EditCarrierType from './EditCarrierType';
import EditCarrierContents from './EditCarrierContents';

function EditPages() {
  const initialActiveTab = localStorage.getItem("activeTab") || 'editMedicine';
  const initialActivePouchTab = localStorage.getItem("activePouchTab") || 'editPouch';
  const initialActiveUserPositionTab = localStorage.getItem("activeUserPositionTab") || 'editUserPosition';

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [activePouchTab, setActivePouchTab] = useState(initialActivePouchTab);
  const [activeUserPositionTab, setActiveUserPositionTab] = useState(initialActiveUserPositionTab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("activePouchTab", activePouchTab);
  }, [activePouchTab]);

  useEffect(() => {
    localStorage.setItem("activeUserPositionTab", activeUserPositionTab);
  }, [activeUserPositionTab]);

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="main-container">
          <ul className="tabs">
            <li className={`tab ${activeTab === 'editMedicine' ? 'active' : ''}`} onClick={() => setActiveTab('editMedicine')}>Edit Medicine</li>
            <li className={`tab ${activeTab === 'editConsumbaleBatch' ? 'active' : ''}`} onClick={() => setActiveTab('editConsumbaleBatch')}>Edit Consumable</li>
            <li className={`tab ${activeTab === 'editUsers' ? 'active' : ''}`} onClick={() => setActiveTab('editUsers')}>Edit Users</li>
            <li className={`tab ${activeTab === 'editCarrier' ? 'active' : ''}`} onClick={() => setActiveTab('editCarrier')}>Edit Carrier</li>
            <li className={`tab ${activeTab === 'editCarrierType' ? 'active' : ''}`} onClick={() => setActiveTab('editCarrierType')}>Edit Carrier Type</li>
            <li className={`tab ${activeTab === 'editCarrierContents' ? 'active' : ''}`} onClick={() => setActiveTab('editCarrierContents')}>Edit Carrier Contents</li>
            <li className={`tab ${activeTab === 'editStation' ? 'active' : ''}`} onClick={() => setActiveTab('editStation')}>Edit Station</li>
            <li className={`tab ${activeTab === 'editUserPosition' ? 'active' : ''}`} onClick={() => setActiveTab('editUserPosition')}>Edit User Position</li>
            <li className={`tab ${activeTab === 'StorageEditPage' ? 'active' : ''}`} onClick={() => setActiveTab('StorageEditPage')}>Edit Storage Units</li>
          </ul>
          {activeTab === 'editMedicine' && <EditMedicine />}
          {activeTab === 'editConsumbaleBatch' && <EditConsumbaleBatch />}          
          {activeTab === 'editUsers' && <EditUsers />}
          {activeTab === 'editCarrier' && <EditCarrier />}
          {activeTab === 'editCarrierType' && <EditCarrierType />}
          {activeTab === 'editCarrierContents' && <EditCarrierContents />}
          {activeTab === 'editStation' && <EditStation />}
          {activeTab === 'editUserPosition' && <EditUserPosition />}
          {activeTab === 'StorageEditPage' && <StorageEditPage />}
        </div>
      </div>
    </div>
  );
}

export default EditPages;