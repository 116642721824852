import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const CarrierTypeForm = ({ token, selectedCarrierType }) => {
  const [carrierTypeName, setCarrierTypeName] = useState('');
  const [carrier, setCarrier] = useState('');
  const [selectedCarrierTypeId, setSelectedCarrierTypeId] = useState(null);

  useEffect(() => {
    if (selectedCarrierType) {
      setCarrierTypeName(selectedCarrierType.carrier_type_name);
      setCarrier(selectedCarrierType.carrier);
      setSelectedCarrierTypeId(selectedCarrierType.id);
    }
  }, [selectedCarrierType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/CarrierTypeName/create/', {
        carrier_type_name: carrierTypeName,
        carrier: carrier,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating carrier type:', error);
    }
  };

  const handleRefresh = () => {
    setCarrierTypeName('');
    setCarrier('');
    setSelectedCarrierTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${carrierTypeName}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/CarrierTypeName/update/${selectedCarrierTypeId}/`, {
        carrier_type_name: carrierTypeName,
        carrier: carrier,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating carrier type:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/CarrierTypeName/delete/${selectedCarrierTypeId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 204) {
        window.location.reload();
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error deleting carrier type:', error);
    }
  };

  const questions = [
    {
      type: 'text',
      text: 'Carrier Type Name',
      value: carrierTypeName,
      handleChange: (e) => setCarrierTypeName(e.target.value),
    },
    {
      type: 'select',
      text: 'Carrier',
      value: carrier,
      handleChange: (e) => setCarrier(e.target.value),
      options: [
        { value: '', label: 'Select Carrier' },
        { value: 'Pouch', label: 'Pouch' },
        { value: 'Vehicle', label: 'Vehicle' },
        { value: 'Medicine_Bag', label: 'Medicine Bag' },
        { value: 'Carrier', label: 'Carrier' },
      ],
    },
  ];

  return (
    <DynamicForm
      title="Add New Carrier Type Name"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedCarrierTypeId}
    />
  );
};

export default CarrierTypeForm;