import Header from '../components/header';
import CarrierTypeTable from '../components/EditTables/CarrierTypeTable';

const token = localStorage.getItem("token");

function EditCarrierType() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <CarrierTypeTable token={token} />
      </div>
    </div>
  );
}

export default EditCarrierType;