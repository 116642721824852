import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const ConsumableBatchForm = ({ token, selectedconsumable }) => {
  const [consumable_name, setconsumableName] = useState('');
  const userBarcode = useSelector((state) => state.user.userBarcode);
  const [batch_number, setBatchNumber] = useState('');
  const [expiry_date, setExpiryDate] = useState('');
  const [consumable_pricing, setconsumablePricing] = useState('');
  const [product_barcode, setproductBarcode] = useState('');
  const [selectedconsumableId, setSelectedconsumableId] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [pack_size, setPackSize] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [consumables, setconsumables] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [medicine_name, setMedicineName] = useState('');
  const [isMedicine, setIsMedicine] = useState(false);

  useEffect(() => {
    fetchconsumables();
    fetchMedicines();
    if (selectedconsumable) {
      setconsumableName(selectedconsumable['Product_Name']);
      setBatchNumber(selectedconsumable['Batch_Number']);
      if (selectedconsumable['Expiry_Date']) {
        const [day, month, year] = selectedconsumable['Expiry_Date'].split('/');
        const expiryDateObj = new Date(`${year}-${month}-${day}`);
        if (!isNaN(expiryDateObj)) {
          const formattedExpiryDate = expiryDateObj.toISOString().split('T')[0];
          setExpiryDate(formattedExpiryDate);
        } else {
          console.error('Invalid expiry date:', selectedconsumable['Expiry_Date']);
        }
      }
      setMedicineName(selectedconsumable['Medicine name'],);
      setconsumablePricing(selectedconsumable['Consumable_Pricing']);
      setproductBarcode(selectedconsumable['Product_Barcode']);
      setPackSize(selectedconsumable['Pack_Size']);
      setSupplier(selectedconsumable['Supplier']);
      setType(selectedconsumable['Type']);
      setStatus(selectedconsumable['Status']);
      setSelectedconsumableId(selectedconsumable.Unique_id);
      setIsMedicine(selectedconsumable['Type'] === 'Medicine');
      }
    }, [selectedconsumable]);

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setType(selectedType);
    setIsMedicine(selectedType === 'Medicine');
  };

  const handleGenerateBarcode = async () => {
    const prefix = 'CB';
    try {
      const response = await fetch(`/api/generate_unique_barcode/${prefix}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const modifiedBarcode = `${data.unique_barcode}`;
        setproductBarcode(modifiedBarcode);
      } else {
        console.error('Error generating barcode:', response.statusText);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/Consumable/create/', {
        Product_Name: consumable_name,
        Batch_Number: batch_number,
        Expiry_Date: expiry_date,
        Consumable_Pricing: consumable_pricing,
        Product_Barcode: String(product_barcode),
        Added_by: userBarcode,
        Date_added: new Date().toISOString().split('T')[0],
        Supplier: supplier,
        Pack_size: pack_size,
        Type: type,
        Status: status,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error creating consumable:', error);
    }
  };

  const handleRefresh = () => {
    setconsumableName('');
    setBatchNumber('');
    setExpiryDate('');
    setconsumablePricing('');
    setproductBarcode('');
    setSupplier('');
    setPackSize('');
    setType('');
    setStatus('');
    setSelectedconsumableId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete batch number ${batch_number} for ${consumable_name}?`)) {
      handleDelete();
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/Consumable/update/${selectedconsumableId}/`, {
        Product_Name: consumable_name,
        Batch_Number: batch_number,
        Expiry_Date: expiry_date,
        Consumable_Pricing: consumable_pricing,
        Product_Barcode: product_barcode,
        Added_by: userBarcode,
        Date_added: new Date().toISOString().split('T')[0],
        Supplier: supplier,
        Pack_size: pack_size,
        Type: type,
        Status: status,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating consumable:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/Consumable/delete/${selectedconsumableId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error deleting consumable:', error);
    }
  };

  const fetchconsumables = async () => {
    try {
      const response = await axios.get('/api/Consumable/');
      if (Array.isArray(response.data)) {
        setconsumables(response.data);
      } else {
        console.error('Expected an array but got:', response.data);
      }
    } catch (error) {
      console.error('Error fetching consumables:', error);
    }
  };

  const fetchMedicines = async () => {
    try {
      const response = await fetch('/api/Medicine/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setMedicines(data);
        } else {
          console.error('Expected an array but got:', data);
        }
      } else {
        console.error('Error fetching medicines:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching medicines:', error);
    }
  };

  return (
    <DynamicForm
      title="Add New Consumable"
      questions={[
        {
          type: 'select',
          text: 'Type',
          value: type,
          handleChange: handleTypeChange,
          options: [
            { value: '', label: 'Select Type' },
            { value: 'Medicine', label: 'Medicine' },
            { value: 'Consumable', label: 'Consumable' },
          ],
        },
        ...(isMedicine
          ? [
              {
                type: 'select',
                text: 'Medicine Name',
                styled: true,
                options: medicines.map((medicine) => ({
                  value: medicine.medicine_name,
                  label: medicine.medicine_name,
                })),
                value: medicine_name,
                handleChange: (e) => setMedicineName(e.target.value),
              },
            ]
          : [
              {
                type: 'text',
                text: 'Consumable Name',
                value: consumable_name,
                handleChange: (e) => setconsumableName(e.target.value),
              },
            ]),
        {
          type: 'text',
          text: 'Batch Number',
          value: batch_number,
          handleChange: (e) => setBatchNumber(e.target.value),
        },
        {
          type: 'date',
          text: 'Expiry Date',
          value: expiry_date,
          handleChange: (e) => setExpiryDate(e.target.value),
        },
        {
          type: 'text',
          text: 'Consumable Pricing',
          value: consumable_pricing,
          handleChange: (e) => setconsumablePricing(e.target.value),
        },
        {
          type: 'text',
          text: 'Product Barcode',
          value: product_barcode,
          handleChange: (e) => setproductBarcode(e.target.value),
          isLocked: true 
        },
        {
          type: 'text',
          text: 'Supplier',
          value: supplier,
          handleChange: (e) => setSupplier(e.target.value),
        },
        {
          type: 'number',
          text: 'Pack Size',
          value: pack_size,
          handleChange: (e) => setPackSize(e.target.value),
        },

        {
          type: 'text',
          text: 'Status',
          value: status,
          handleChange: (e) => setStatus(e.target.value),
        },
        ...(!product_barcode ? [{
          text: 'Generate Barcode',
          type: 'generateBarcode'
        }] : [])
      ]}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleGenerateBarcode={() => handleGenerateBarcode(setproductBarcode)} 
      setproductBarcode={setproductBarcode} 
      selectedId={selectedconsumableId}
      showSearchBar={true}
    />
  );
}

export default ConsumableBatchForm;