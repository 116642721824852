import Header from '../components/header';
import CarrierContentsTable from '../components/EditTables/CarrierContentsTable';
import './EditUsers';

const token = localStorage.getItem("token");

function EditCarrierContents() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <CarrierContentsTable token={token} />
      </div>
    </div>
  );
}

export default EditCarrierContents;