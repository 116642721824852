import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const CarrierContentsForm = ({ token, selectedCarrierType }) => {
  const [carrierTypeName, setCarrierTypeName] = useState('');
  const [productName, setProductName] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [redTagAmount, setRedTagAmount] = useState('');
  const [selectedCarrierTypeId, setSelectedCarrierTypeId] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (selectedCarrierType) {
      setCarrierTypeName(selectedCarrierType['Carrier Type Name']);
      setProductName(selectedCarrierType['Product Name']);
      setMaxAmount(selectedCarrierType['Max Amount']);
      setMinAmount(selectedCarrierType['Min Amount']);
      setRedTagAmount(selectedCarrierType['Red Tag Amount']);
      setSelectedCarrierTypeId(selectedCarrierType.Unique_id);
    }
  }, [selectedCarrierType]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/Consumable/', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/CarrierType/create/', {
        carrier_type_name: carrierTypeName,
        product_name: productName,
        max_amount: maxAmount,
        min_amount: minAmount,
        red_tag_amount: redTagAmount,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error creating carrier type:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/CarrierType/update/${selectedCarrierTypeId}/`, {
        carrier_type_name: carrierTypeName,
        product_name: productName,
        max_amount: maxAmount,
        min_amount: minAmount,
        red_tag_amount: redTagAmount,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating carrier type:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/CarrierType/delete/${selectedCarrierTypeId}/`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error deleting carrier type:', error);
    }
  };

  const handleRefresh = () => {
    setCarrierTypeName('');
    setProductName('');
    setMaxAmount('');
    setMinAmount('');
    setRedTagAmount('');
    setSelectedCarrierTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${productName}?`)) {
      handleDelete();
    }
  };

  const questions = [
    {
      text: 'Product Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setProductName(e.target.value),
      value: productName,
      options: products.map(product => ({ value: product.product_name, label: product.product_name })),
    },
    {
      text: 'Carrier Type Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setCarrierTypeName(e.target.value),
      value: carrierTypeName,
      options: products.map(product => ({ value: product.carrier_type_name, label: product.carrier_type_name })),
    },
    {
      text: 'Max Amount',
      type: 'number',
      handleChange: (e) => setMaxAmount(e.target.value),
      value: maxAmount,
    },
    {
      text: 'Min Amount',
      type: 'number',
      handleChange: (e) => setMinAmount(e.target.value),
      value: minAmount,
    },
    {
      text: 'Red Tag Amount',
      type: 'number',
      handleChange: (e) => setRedTagAmount(e.target.value),
      value: redTagAmount,
    }
  ];

  return (
    <DynamicForm
      title="Add New Carrier Type"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedCarrierTypeId}
    />
  );
};

export default CarrierContentsForm;